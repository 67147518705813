import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import config from '../utils/siteConfig'

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost

    return (
      <>
        <MetaData data={data} location={location} type="article" />

        <Helmet>
          <style type="text/css">{`${post.codeinjection_styles}`}</style>
          <script>{`
          var ticking = false;
          var commentsLoaded = false;
          var fbApi = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v5.0&appId=280634731984273&autoLogAppEvents=1';

          function removeScripts(lib) {
            var scriptInstances = document.querySelectorAll('[src^="' + lib + '"]');
            for (var i = 0; i < scriptInstances.length; i++) {
              var theScript = scriptInstances[i];
              theScript.parentNode.removeChild(theScript);
            }
          }

          function loadAPI(loadURI) {
            var js = document.createElement('script');
            js.src = loadURI;
            document.getElementsByTagName('body')[0].appendChild(js);
            return js;
          }

          function onScroll() {
            requestTick();
          }

          function requestTick() {
            if (!ticking) {
              requestAnimationFrame(update);
            }

            ticking = true;
          }

          function update() {
            ticking = false;

            var comments = document.getElementById('comments'),
              rect;
            if (comments) rect = comments.getBoundingClientRect();
            if (!(document.querySelector('#comments') && document.querySelector('#comments').childElementCount)) {
              commentsLoaded = false;
            }

            if (!commentsLoaded && (window.scrollY > 1000 || (rect && rect.top < window.innerHeight))) {
              commentsLoaded = true;
              removeScripts(fbApi);
              loadAPI(fbApi);
            }
          }

          var commentsInterval = setInterval(function() {
            if (document.querySelector('#comments') && typeof FB !== 'undefined' &&
              document.querySelector('#comments').childElementCount === 0) {
              clearInterval(commentsInterval);
              commentsLoaded = true;
              FB.XFBML.parse();
            }
          }, 5000);

          window.addEventListener('scroll', onScroll);
        `}</script>
        </Helmet>

        <Layout>
          <div className="container">
            <article className="content">
              {post.feature_image ? (
                <figure className="post-feature-image">
                  <img
                    src={post.feature_image}
                    alt={post.title}
                                />
                </figure>
                        ) : null}

              <section className="post-full-content">
                <h1 className="content-title">{post.title}</h1>

                {/* The main post content */}
                <section
                  className="content-body load-external-scripts"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                            />

                {/* Facebook like and share buttons: */}
                <div
                  className="fb-like"
                  data-href={config.siteUrl + location.pathname}
                  data-width="480"
                  data-layout="standard"
                  data-action="like"
                  data-size="small"
                  data-share="true"
                            />

                {/* Facebook comments: */}
                <div
                  className="fb-comments"
                  id="comments"
                  data-href={config.siteUrl + location.pathname}
                  data-width="480"
                  data-numposts="8"
                            />

                <hr />

                <h3>
                                Ready to learn more about JavaScript
                                development, organization and productivity tips?
                </h3>
                <p>
                  <a href={config.siteUrl + `/`} target="_top">
                                    Click here to read more articles on
                                    JavaScript, philosophy and life hacks!
                  </a>
                </p>
                <br />
              </section>
            </article>
          </div>
        </Layout>
      </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
